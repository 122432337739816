



























































import TopBar from "@/components/common/TopBar.vue";

import Vue from "vue";
import store from "../../store";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import {
  callCalc,
  callPrevCalc,
  callYearsums,
  callPrevYearsums,
  callPlan,
} from "../../helper/utils";
import api from "../../api";
import lodash from "lodash";

import UmsatzerloeseVergleich from "@/components/DashboardItems/UmsatzerloeseVergleich.vue";
import Umsatzrentabilitaet from "@/components/DashboardItems/Umsatzrentabilitaet.vue";
import Umsatzwachstum from "@/components/DashboardItems/Umsatzwachstum.vue";
import Umsatzerloese from "@/components/DashboardItems/Umsatzerloese.vue";
import Top10Umsatzbereiche from "@/components/DashboardItems/Top10Umsatzbereiche.vue";
import Top5UmsatzerloeseBereiche from "@/components/DashboardItems/Top5UmsatzerloeseBereiche.vue";
import Top5BereicheGesamt from "@/components/DashboardItems/Top5BereicheGesamt.vue";
import Top10KundenUmsatz from "@/components/DashboardItems/Top10KundenUmsatz.vue";
import Top10KundenBereichUmsatz from "@/components/DashboardItems/Top10KundenBereichUmsatz.vue";
import Top10KundenBereichZweitUmsatz from "@/components/DashboardItems/Top10KundenBereichZweitUmsatz.vue";
import Top10KundenBereichDrittUmsatz from "@/components/DashboardItems/Top10KundenBereichDrittUmsatz.vue";
import Top10KundenBereichViertUmsatz from "@/components/DashboardItems/Top10KundenBereichViertUmsatz.vue";
import Top10KundenBereichFuenftUmsatz from "@/components/DashboardItems/Top10KundenBereichFuenftUmsatz.vue";

@Component({
  components: {
    TopBar,
    UmsatzerloeseVergleich,
    Umsatzrentabilitaet,
    Umsatzwachstum,
    Umsatzerloese,
    Top10Umsatzbereiche,
    Top5UmsatzerloeseBereiche,
    Top5BereicheGesamt,
    Top10KundenUmsatz,
    Top10KundenBereichUmsatz,
    Top10KundenBereichZweitUmsatz,
    Top10KundenBereichDrittUmsatz,
    Top10KundenBereichViertUmsatz,
    Top10KundenBereichFuenftUmsatz,
  },
})
export default class Umsatzanalyse extends Vue {
  initPage = false;

  get topBarData() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  get planSelected() {
    return store.getters.planSelected;
  }

  get prevCalcs() {
    return store.getters.previousCalcs;
  }

  get currentCalcs() {
    return store.getters.currentCalcs;
  }

  get calculationPlan() {
    return {
      data: store.getters.currentCalcs,
    };
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
      rangeStep: store.getters.rangeStep,
      comparison: store.getters.planSelected,
    };
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  pushStateHistory(
    stb: any,
    mandant: any,
    year: any,
    range: any,
    comparison: any
  ) {
    const selectedStb = this.biAgency != null ? this.biAgency : stb;

    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year) +
      "/" +
      encodeURIComponent(range) +
      "/" +
      encodeURIComponent(comparison);

    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  loadFromPlan() {
    let client = JSON.parse(String(localStorage.getItem("client")));
    let year = JSON.parse(String(localStorage.getItem("year")));
    api
      .getPlanData(client, year)
      .then((res: any) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        let prevData = null;
        if (this.prevCalcs) {
          prevData = lodash.cloneDeep(this.prevCalcs);
        } else {
          prevData = { BWA: lodash.cloneDeep(this.currentCalcs["BWA"]) };
        }
        if (res && res.data) {
          if (prevData) {
            prevData["BWA"] = res.data.plan_month;
          }
        } else {
          if (prevData) {
            const l = prevData["BWA"]["legend"];
            for (var i = 0; i < l.length; i++) {
              prevData["BWA"]["total"][l[i][0]] = 0;
              for (var month = 1; month < 13; month++) {
                prevData["BWA"][month][l[i][0]] = 0;
              }
            }
          }
        }
        store.dispatch("updatePreviousCalcs", prevData);
      })
      .catch((err) => {
        store.dispatch("updatePreviousCalcs", null);
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  @Watch("topBarData")
  onTopBarDataChange(load: boolean = false) {
    if (this.topBarData.year && this.topBarData.client) {
      if (!store.getters.isCalcCalled || load) {
        callCalc();
      }
      if (store.getters.planSelected) {
        callPlan(store.getters.selectedYear[0]);
      }
      if (
        (!store.getters.isPrevCalcCalled || load) &&
        !store.getters.planSelected
      ) {
        callPrevCalc();
      }
      if (!store.getters.isYearsumsCalled) {
        callYearsums();
      }
      if (!store.getters.isPrevYearsumsCalled) {
        callPrevYearsums();
      }

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }

  @Watch("topBarNavigation")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let mandant: string = this.topBarNavigation.client[1];
    let year: string = this.topBarNavigation.year[0];
    let stb: string =
      this.topBarNavigation.client && this.topBarNavigation.client[0];

    this.pushStateHistory(
      stb,
      mandant,
      year,
      store.getters.rangeStep,
      store.getters.planSelected ? "planjahr" : "vorjahr"
    );
  }

  @Watch("calculationPlan")
  loadPlan() {
    let initPlan = JSON.parse(String(localStorage.getItem("initPlan")));
    if (initPlan) {
      this.loadFromPlan();
      localStorage.setItem("initPlan", JSON.stringify(false));
    }
  }

  mounted() {
    this.initPage = true;

    this.onTopBarDataChange(true);

    if (localStorage.getItem("from") == "ums_table") {
      (document.getElementById("ums_table") as any).scrollIntoView();
      localStorage.removeItem("from");
    }
  }
}
